
































































































import { SfSidebar } from '@storefront-ui/vue';
import {
  ref,
  defineComponent,
  useRouter,
  useContext,
  computed,
  Ref,
} from '@nuxtjs/composition-api';
import { useUiHelpers, useUiState } from '~/composables';
import { useTraverseCategory } from '~/modules/catalog/category/helpers/useTraverseCategory';
import { CategoryTree } from '~/modules/GraphQL/types';
import { MS_PROFESSIONALS_PAGES } from '~/utilities/constants';
import { useMobileCategoryTree } from './logic';

interface ItemChild {
  name: string;
  link?: string;
  uid?: string;
  children?: Array<any>;
  url_path?: string;
}

interface ListItem {
  title: string;
  children?: Array<ItemChild> | Array<CategoryTree> | Array<ListItem>;
  link: string;
}

export default defineComponent({
  components: {
    SfSidebar,
  },
  setup() {
    const { isMobileMenuOpen, toggleMobileMenu } = useUiState();
    const { getCatLink } = useUiHelpers();
    const router = useRouter();
    const app = useContext();

    const defaultCurrentItem = 0;

    const currentItemIndex = ref(defaultCurrentItem);
    const currentSubItemUID = ref(null);

    const { categoryAncestors: initialHistory, categoryTree } = useTraverseCategory();

    const navigate = (category: CategoryTree | any) => {
      toggleMobileMenu();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const path = app.localePath(getCatLink(category) as string);
      router.push(path);
    };

    const visitItemPage = (item: ListItem) => {
      const path = app.localePath(item.link as string);
      router.push(path);
    };

    const itemIsLink = (item: ListItem) => item.children.length === 0 && item.link;
    const subitemIsLink = (item: ItemChild) => {
      if (item.children && item.url_path) {
        return item.children.length === 0 && item.url_path;
      }
      return !!item.url_path;
    };

    const expandItem = (index: number, item: ListItem) => {
      if (itemIsLink(item)) {
        visitItemPage(item);
        return;
      }
      currentItemIndex.value = index;
    };

    const expandSubItem = (category: ItemChild) => {
      if (subitemIsLink(category)) {
        navigate(category);
        return;
      }
      currentSubItemUID.value = category.uid;
    };
    // A category with no child categories can't be entered into - it can only navigated to
    const initialHistoryWithSnippedSubcategoryLessTail = initialHistory.value.at(-1)?.children?.length
      ? initialHistory.value
      : initialHistory.value.slice(0, -1);

    const {
      current: currentCategory,
      history,
      onGoCategoryUp,
      onGoCategoryDown,
    } = useMobileCategoryTree(initialHistoryWithSnippedSubcategoryLessTail);

    const itemsIncludedInMenu = computed(() => {
      const topLevelItems = categoryTree.value.children;
      return topLevelItems.filter((item) => item.include_in_menu);
    });

    const listItems: Ref<Array<ListItem | any>> = ref([
      {
        title: 'shop modern art',
        children: itemsIncludedInMenu.value,
        link: null,
      },
      {
        title: 'm²mag',
        children: [],
        link: '/inside-modern-squared/blog',
      },
      {
        title: 'professionals',
        children: MS_PROFESSIONALS_PAGES.map((item) => ({
          name: item.page_name,
          url_path: item.page_link,
        })),
        link: null,
      },
    ]);

    return {
      currentCategory,
      onGoCategoryUp,
      onGoCategoryDown,
      history,
      navigate,
      isMobileMenuOpen,
      toggleMobileMenu,
      itemsIncludedInMenu,
      listItems,
      currentItemIndex,
      expandItem,
      itemIsLink,
      subitemIsLink,
      expandSubItem,
      currentSubItemUID,
    };
  },
});
